//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  model: {
    prop: 'modelValue',
    event: 'change'
  },
  props: {
    label: { type: String, default: '', required: true },
    modelValue: { default: '' },
    value: { type: String, default: undefined },
    height: { type: String, default: 'auto' }
  },
  computed: {
    isChecked() {
      return this.modelValue == this.value;
    }
  }
};
