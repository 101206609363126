var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "label",
      {
        class: [
          "wrapper flex items-center text-center",
          _vm.isChecked && "active"
        ],
        style: { height: _vm.height }
      },
      [
        _c(
          "div",
          { staticClass: "mb-xs label" },
          [_vm._t("label", [_vm._v(" " + _vm._s(_vm.label) + " ")])],
          2
        ),
        _c("input", {
          staticClass: "checkbox",
          attrs: { type: "radio" },
          domProps: { checked: _vm.isChecked, value: _vm.value },
          on: {
            change: function($event) {
              return _vm.$emit("change", $event.target.value)
            }
          }
        }),
        _c("span", { staticClass: "checkmark" }),
        _c("div", [_vm._t("body")], 2)
      ]
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }